import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import TripleColumnBlock from "@components/tripleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import ReactPlayer from 'react-player'

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Personal Style - Diane B. Morris - Websuasion Podcast"
        ogDescription="Diane is a personal stylist, personal shopper, author, and dietitian who is committed to creating her clients' authentic image statements."
        image="/images/podcast/season-one/diane-b-morris.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
						<h1>Personal Style with Diane B. Morris</h1>
						<h3>BrandtasticYou</h3>
						<p>Diane is a personal stylist, personal shopper, author, and dietitian who is committed to creating her clients' authentic image statements. After 27 years of working in a health care corporate environment, she started BrandtasticYou. Her book, Build Your Confidence through Wardrobe, Color &amp; Style is available on Amazon.com.</p>
						<p>In the fourth episode of Websuasion Conversation, we discuss Diane's journey and the process of finding your personal business style.</p>
		        <p>
		          <QuickLinkButton
		            content={"Diane's Book on Amazon"}
		            url={"https://www.amazon.com/gp/product/1539569470/"}
		            icon={"linkify"}
		          />
		        </p>
					</div>
        }
        textRight={
          <div className="centered">
            <img src="/images/podcast/season-one/diane-b-morris.jpg" className="rounded-square" alt="Personal Style with Diane B. Morris" width="300" />
					</div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        text={
	          <div>
							<h2>Listen Now</h2>
						  <AudioPlayer
						    src="https://websuasion-conversation.s3.amazonaws.com/WebsuasionConversationEpisode004-DianeMorris.mp3"
						  />
							<h3>Subscribe to the podcast</h3>
							<p>
								<a href="https://itunes.apple.com/us/podcast/websuasion-conversation/id1454464479" target="_blank">
									<img src="/images/podcast/apple-podcasts-150x150.png" className="icon" alt="Websuasion Conversasion on Apple Podcasts" width="50" />
								</a>
								<a href="https://open.spotify.com/show/521lym6rKJPoWQZbeUROuG" target="_blank">
									<img src="/images/podcast/spotify-150x150.png" className="icon" alt="Websuasion Conversasion on Spotify" width="50" />
								</a>
							</p>
							<p>
								Podcast RSS feed:<br /><br />

								<span className="red">https://websuasion.com/podcast.xml</span>
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Hard Work</h2>
							<p>
								 Diane of BrandtasticYou tells us how her family instilled in her a focus on hard work.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/UJlv9P1VOrU'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Life is Too Short</h2>
							<p>
								Diane tells us a harrowing story of the car wreck that changed her life. 
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/VDATm3T2ed8'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>A Love For Fashion</h2>
							<p>
								Diane talks to us about her love for fashion and how she guides clients to their personal style.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/vZ6tixGXuRc'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Build Your Confidence Through Wardrobe, Color & Style</h2>
							<p>
								Diane B. Morris tells us about her book Build Your Confidence Through Wardrobe, Color & Style: Be Your Best Self.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/EfxFTHvuBrg'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>3 Questions About Your Business Wardrobe</h2>
							<p>
								Diane tells us the three questions she asks her clients to get them to focus on dressing for success. 
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/-kOqY9gYxgo'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Personality Dressing Style for Men</h2>
							<p>
								Diane discusses her Personality Dressing style guide for men.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/snOIj6vVQIw'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Personality Dressing Style for Women</h2>
							<p>
								Diane talks about her Personality Dressing style guide for women.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/ikO2mienU40'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Local and Virtual Wardrobe Assistance</h2>
							<p>
								Diane tells us her program for getting clients to their most marketable self.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/TakoYHore1o'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h3>Your Multi-Tier Ad Campaign</h3>
							<p>
								This week's homework is for you to think about a guidance-based article you can write as content for the first tier of your advertising campaign. Then, think about what you can offer in the second ad tier that would be enticing enough for a prospect to share their email and phone number with you.
							</p>
	          </div>
	        }
	        textRight={
	          <div>
							<img src="/images/podcast/season-one/multi-tier-ads.jpg" className="rounded-image" alt="Content Marketing" width="620" />
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
